// scss-lint:disable NestingDepth, SelectorDepth
.sidebar {
  padding: $sidebar-padding;
  overflow: hidden;
  color: $sidebar-color;
  background: $sidebar-bg;
  @include borders($sidebar-borders);

  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

  .sidebar-nav {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include sidebar-width($sidebar-borders, $sidebar-width);

    &::-webkit-scrollbar {

      position: absolute;
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none;
    }

    // &::-webkit-scrollbar-button {    }

    &::-webkit-scrollbar-track {
      background-color: lighten($sidebar-bg, 5%);
      border-right: 1px solid darken($sidebar-bg, 5%);
      border-left: 1px solid darken($sidebar-bg, 5%);
    }

    // &::-webkit-scrollbar-track-piece {    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($sidebar-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }

    // &::-webkit-scrollbar-corner {    }

    // &::-webkit-resizer {    }
  }

  .nav {
    @include sidebar-width($sidebar-borders, $sidebar-width);
    flex-direction: column !important;

    .nav-title {
      padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
      font-size: 11px;
      font-weight: 600;
      color: $sidebar-nav-title-color;
      text-transform: uppercase;
    }

    .divider {
      height: 10px;
    }

    .nav-item {
      position: relative;
      margin: 0;
      transition: background .3s ease-in-out;

      ul {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height .3s ease-in-out;

        li {
          padding: 0;
          list-style: none;
        }
      }

      .nav-link {
        display: block;
        padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
        color: $sidebar-nav-link-color;
        text-decoration: none;
        background: $sidebar-nav-link-bg;
        @include borders($sidebar-nav-link-borders);
        @if $enable-sidebar-nav-rounded {
          border-radius: $border-radius;
        }

        &:hover {
          color: $sidebar-nav-link-hover-color !important;
          background: $sidebar-nav-link-hover-bg !important;
          @include borders($sidebar-nav-link-hover-borders);

          i {
            color: $sidebar-nav-link-hover-icon-color;
          }
        }

        &.active {
          color: $sidebar-nav-link-active-color;
          background: $sidebar-nav-link-active-bg;
          @include borders($sidebar-nav-link-active-borders);

          i {
            color: $sidebar-nav-link-active-icon-color;
          }
        }

        [class^="icon-"], [class*=" icon-"] {
          display: inline-block;
          margin-top: -4px;
          vertical-align: middle;
        }

        i {
          width: 20px;
          margin: 0 ($sidebar-nav-link-padding-x / 2) 0 0;
          font-size: 14px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
        }

        .badge {
          float: right;
          margin-top: 2px;
          // margin-left: 10px;
        }

        &.nav-dropdown-toggle {

          &::before {
            position: absolute;
            top: ($sidebar-nav-link-padding-y + ($font-size-base / 4));
            right: $sidebar-nav-link-padding-x;
            display: block;
            width: ($font-size-base);
            height: ($font-size-base);
            padding: 0;
            font-size: $font-size-base;
            line-height: ($font-size-base * 3 / 4);
            text-align: center;
            content: "\2039";
            transition: .3s;
          }
        }
      }

      &.nav-dropdown {
        &.open {
          background: $sidebar-nav-dropdown-bg;
          @include borders($sidebar-nav-dropdown-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }
          > ul, > ol {
            // display: inline;
            max-height: 1000px;
          }

          .nav-link {
            color: $sidebar-nav-dropdown-color;
            border-left: 0 !important;
          }

          > .nav-link.nav-dropdown-toggle::before {
            transform: rotate(-90deg);
          }

          .nav-dropdown.open {
            border-left: 0;
          }
        }
        &.nt {
          transition: 0s !important;
          > ul, > ol {
            transition: 0s !important;
          }
          .nav-link {
            &.nav-dropdown-toggle {

              &::before {
                transition: 0s !important;
              }
            }
          }
        }
      }

      .nav-label {
        display: block;
        padding: ($sidebar-nav-link-padding-y / 8) $sidebar-nav-link-padding-x;
        color: $sidebar-nav-title-color;

        &:hover {
          color: $sidebar-color;
          text-decoration: none;
        }

        i {
          width: 20px;
          margin: -3px ($sidebar-nav-link-padding-x / 2) 0 0;
          font-size: 10px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
          vertical-align: middle;
        }
      }

      @if (lightness( $sidebar-bg ) < 40) {
        .progress {
          background-color: lighten($sidebar-bg, 15%) !important;
        }
      }
    }

    .nav-dropdown-items
    {
      .nav-item {
        position: relative;
        margin: 0;
        transition: background .3s ease-in-out;

        ul {
          max-height: 0;
          padding: 0;
          margin: 0;
          overflow-y: hidden;
          transition: max-height .3s ease-in-out;

          li {
            padding: 0;
            list-style: none;
          }
        }

        .nav-link {
          display: block;
          padding: 0.75rem 0rem 0.75rem 1.5rem;  //$sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
          color: $sidebar-nav-link-color;
          text-decoration: none;
          background: $sidebar-nav-link-bg;
          @include borders($sidebar-nav-link-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }

          &:hover {
            color: $sidebar-nav-link-hover-color !important;
            background: $sidebar-nav-link-hover-bg !important;
            @include borders($sidebar-nav-link-hover-borders);

            i {
              color: $sidebar-nav-link-hover-icon-color;
            }
          }

          &.active {
            color: $sidebar-nav-link-active-color;
            background: $sidebar-nav-link-active-bg;
            @include borders($sidebar-nav-link-active-borders);

            i {
              color: $sidebar-nav-link-active-icon-color;
            }
          }

          [class^="icon-"], [class*=" icon-"] {
            display: inline-block;
            margin-top: -4px;
            vertical-align: middle;
          }

          i {
            width: 20px;
            margin: 0 ($sidebar-nav-link-padding-x / 2) 0 0;
            font-size: 14px;
            color: $sidebar-nav-link-icon-color;
            text-align: center;
          }

          .badge {
            float: right;
            margin-top: 2px;
            // margin-left: 10px;
          }

          &.nav-dropdown-toggle {

            &::before {
              position: absolute;
              top: ($sidebar-nav-link-padding-y + ($font-size-base / 4));
              right: $sidebar-nav-link-padding-x;
              display: block;
              width: ($font-size-base);
              height: ($font-size-base);
              padding: 0;
              font-size: $font-size-base;
              line-height: ($font-size-base * 3 / 4);
              text-align: center;
              content: "\2039";
              transition: .3s;
            }
          }
        }

        &.nav-dropdown {
          &.open {
            background: $sidebar-nav-dropdown-bg;
            @include borders($sidebar-nav-dropdown-borders);
            @if $enable-sidebar-nav-rounded {
              border-radius: $border-radius;
            }
            > ul, > ol {
              // display: inline;
              max-height: 1000px;
            }

            .nav-link {
              color: $sidebar-nav-dropdown-color;
              border-left: 0 !important;
            }

            > .nav-link.nav-dropdown-toggle::before {
              transform: rotate(-90deg);
            }

            .nav-dropdown.open {
              border-left: 0;
            }
          }
          &.nt {
            transition: 0s !important;
            > ul, > ol {
              transition: 0s !important;
            }
            .nav-link {
              &.nav-dropdown-toggle {

                &::before {
                  transition: 0s !important;
                }
              }
            }
          }
        }

        .nav-label {
          display: block;
          padding: ($sidebar-nav-link-padding-y / 8) $sidebar-nav-link-padding-x;
          color: $sidebar-nav-title-color;

          &:hover {
            color: $sidebar-color;
            text-decoration: none;
          }

          i {
            width: 20px;
            margin: -3px ($sidebar-nav-link-padding-x / 2) 0 0;
            font-size: 10px;
            color: $sidebar-nav-link-icon-color;
            text-align: center;
            vertical-align: middle;
          }
        }

        @if (lightness( $sidebar-bg ) < 40) {
          .progress {
            background-color: lighten($sidebar-bg, 15%) !important;
          }
        }
      }

    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      .sidebar-nav {
        @include sidebar-width($sidebar-borders, $sidebar-compact-width);
      }

      .nav {
        @include sidebar-width($sidebar-borders, $sidebar-compact-width);

        .nav-title {
          text-align: center;
        }

        .nav-item {
          width: $sidebar-compact-width;
          border-left: 0 !important;

          .nav-link {
            text-align: center;

            i {
              display: block;
              width: 100%;
              margin: $spacer / 4 0;
              font-size: 24px;
            }

            .badge {
              position: absolute;
              top: 18px;
              right: 10px;
            }

            &.nav-dropdown-toggle {

              &::before {
                top: 30px;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-minimized {
    .hidden-cn {
      display: none;
    }
    .sidebar {
      z-index: $zindex-sticky - 1;

      &:hover {
        overflow: visible;
      }

      .sidebar-nav {
        overflow: visible;
        @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
      }

      .nav {
        @include sidebar-width($sidebar-borders, $sidebar-minimized-width);

        .nav-title, .divider {
          display: none;
        }

        .nav-item {
          width: $sidebar-minimized-width;
          overflow: hidden;
          border-left: 0 !important;

          ul {
            background: $sidebar-bg;
          }

          .nav-link {
            position: relative;
            padding-left: 0;
            margin: 0;
            white-space: nowrap;
            border-left: 0 !important;

            &.nav-dropdown-toggle {

              &::before {
                display: none;
              }
            }

            i {
              display: block;
              float: left;
              width: $sidebar-minimized-height;
              padding: 0;
              margin: 0 !important;
              font-size: 18px;
            }

            .badge {
              position: absolute;
              // top: 14px;
              right: 15px;
              display: none;
            }

            &:hover {
              width: $sidebar-width;

              .badge {
                display: inline;
              }
            }
          }

          ul {
            position: absolute;
            left: $sidebar-minimized-width;

            li {
              position: relative;
              padding: 0;

              .nav-link {
                width: $sidebar-width - $sidebar-minimized-width;
              }

              ul, ol {
                position: absolute;
                top: 0;
                left: 100%;
              }
            }
          }

          &.nav-dropdown {
            &.open {
              background: $sidebar-nav-link-active-bg;
              > .nav-link i {
                color: $sidebar-nav-link-active-icon-color;
              }
              > ul, > ol {
                display: none;
              }
            }
          }

          &:hover {
            width: $sidebar-width + $sidebar-minimized-width;
            overflow: visible;
            background: $sidebar-nav-link-hover-bg;
            transition: 0s;

            > .nav-link {
              width: $sidebar-width + $sidebar-minimized-width;
            }

            > ul, > ol {
              display: inline;
              max-height: 10000px;
              transition: 0s;

              li {
                width: $sidebar-width;

                .nav-link {
                  width: $sidebar-width;
                }
              }
            }

            &.nav-dropdown {
              &.open {
                > ul, > ol {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }
}
