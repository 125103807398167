body {font-family: 'Maven Pro', sans-serif;font-size: 15px;color: #000;}
body a {color: #000;text-decoration: none;font-weight: 500;}
h1, .h1 {font-weight: bold;}
.button, button.button, .btn-primary {font-family: 'Maven Pro', sans-serif;font-size:14px;font-style: normal;text-transform: uppercase;border-radius: 80px;font-weight: 500;background: #5cb85c;
    border: 1px solid #5cb85c;min-width: 120px;height: 40px;}
button.button-white, .button-white {background:transparent;border-color:#fff;color: #fff;}    
.btn {font-family: 'Maven Pro', sans-serif;}    
.button:hover, button.button:hover, .btn-primary:hover, div.ng-wizard-buttons-container div.ng-wizard-buttons button:hover, .button:focus, button.button:focus, .btn-primary:focus, div.ng-wizard-buttons-container div.ng-wizard-buttons button:focus {background: #5bc0de; border-color: #5bc0de}
button.button-white:hover, .button-white:hover{background:#fff;color: #5cb85c;box-shadow: none;}
.btn-secondary {color: #000;background-color: #ccc;border-color: #ccc;font-style: normal;border-radius: 80px;min-width: 120px;}
// .app-header.navbar .navbar-brand {background-size: 65px auto;}
.sidebar-fixed .sidebar {background: #333;}
.sidebar .nav .nav-item i.icon-magnifier {color: #fff;position: relative;top: 1px;}
.sidebar .nav .nav-item .nav-link.nav-dropdown-toggle::before, .sidebar .nav .nav-item .navbar .nav-dropdown-toggle.dropdown-toggle::before, .navbar .sidebar .nav .nav-item .nav-dropdown-toggle.dropdown-toggle::before {transform: rotate(180deg);font-size: 20px;}
.button-box {background: #fff;padding: 40px;text-align: center; box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);border-radius: 6px; border-top: 5px solid #5cb85c;}
.video-box {background: #fff;padding: 15px;text-align: center; box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);border-radius: 6px; border-top: 5px solid #5cb85c;}
.sidebar-fixed .sidebar {width: auto;}
.sidebar {padding: 0 0 0 0rem;}
.homepageboxes {text-align: center; margin-top: 50px;}
.form-group.button-box-wrap {width: 28%;margin: 0 auto;display: inline-block;margin-right: 50px;-webkit-transition: .5s;transition: .5s;}
.form-group.button-box-wrap:hover {-webkit-transform: scale(1.1);transform: scale(1.1);}
.form-group.button-box-wrap:last-child {margin-right: 0;}
.button-box button.btn {width: auto;margin: 0 auto;margin-top: 20px;}
.button-box .button-box-icon {font-size: 45px;background: #efefef;border-radius: 100px;line-height: 106px;width:100px;height:100px;margin:0 auto;}
.card-group .card .card-body .button-box{-webkit-transition: .5s;transition: .5s;}
.card-group .card .card-body .button-box:hover {-webkit-transform: scale(1.1);transform: scale(1.1);}
.card-group-noshadow {box-shadow: none !important;}
.card-group-noshadow .card {background-color: unset !important;}
.sidebar .sidebar-nav, .sidebar .nav {width: 240px;}
.navbar-expand-lg .navbar-nav .nav-link:hover {color: #5cb85c;}
.button-box-title {margin-top: 20px;text-align: center;    font-size: 20px;}
.video-box-title {text-align: center;    font-size: 18px; margin-bottom: 10px;}
.card {border: none;}
.card-group, .registerBox .card-body, .infoBox {box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);border-radius: 6px;}
.input-group .form-control {height: 46px;line-height: 46px;font-size: 15px;}
select.form-control:not([size]):not([multiple]) {height: 46px;font-size: 15px;}
.input-group .p-input-form-control {width: 100% !important;}
.loginForm p.text-muted {margin-bottom: 25px;}
.bg-primary {background-color: #5cb85c !important;}
.card.bg-primary {border-color: #5cb85c;}
.loginForm a.forgotPass{color: #000;font-size: 14px;margin-bottom: 5px;font-style: normal; display: block;}
.loginForm a.search-btn{color: #000;font-size: 14px;margin-bottom: 5px;font-style: normal; display: block;}
.loginForm .card-body, .loginbuttonwrap, .loginBox, .registerBox{text-align: center;}
.card-body.text-center img {max-width: 195px;margin-bottom:20px;}
.infoBox {border-top: 5px solid #5cb85c;}
.infoBox .card {margin-bottom: 0;padding-bottom: 10px;}
.sidebar .nav .nav-dropdown-items .nav-item .nav-link.active, .sidebar .nav .nav-dropdown-items .nav-item .navbar .active.dropdown-toggle, .navbar .sidebar .nav .nav-dropdown-items .nav-item .active.dropdown-toggle, .sidebar .nav .nav-dropdown-items .nav-item .nav-link.active, .sidebar .nav .nav-dropdown-items .nav-item .navbar .active.dropdown-toggle, .navbar .sidebar .nav .nav-dropdown-items .nav-item .active.dropdown-toggle, .sidebar .nav .nav-dropdown-items .nav-item .navbar .active.dropdown-toggle, .navbar .sidebar .nav .nav-dropdown-items .nav-item .active.dropdown-toggle {color: #fff;background: #20a8d8;}
.sidebar .nav .nav-dropdown-items .nav-item .nav-link.active i, .sidebar .nav .nav-dropdown-items .nav-item .navbar .active.dropdown-toggle i, .navbar .sidebar .nav .nav-dropdown-items .nav-item .active.dropdown-toggle i {
    color: #fff;
}
.infoBox .customLG {padding: 0;}
.withoutBg {color: #000;font-size: 14px;font-style: normal;}
.dropdown-menu.show:before {content: "";width: 0;    height: 0;    border-left: 8px solid transparent;border-right: 8px solid transparent;border-bottom: 8px solid #5db85d;    position: absolute;right: 0;top: -8px;}
.dropdown-toggle::after {position: relative;top: 2px;}
li.nav-item.dropdown.open.show .dropdown-toggle::after {transform: rotate(180deg);}
.resetPasswordBox .card-group {border-top: 5px solid green;}
.resetPasswordBox {text-align: center;}
.resetPasswordBox h1 {margin-bottom: 20px;}
.form-control {height: 46px;line-height: 46px;}
.breadcrumb {display: none;}
.ng-select.ng-select-multiple .ng-select-container {min-height: 46px;padding-left: 10px;}

//styles added AI
$new-base-font:'Maven Pro', sans-serif;
$new-base-color:#5cb85c;
$new-base-dark-color:#333333;
$new-base-dark-blue: #343e51;
$new-base-banner-bg: #efefef;
$new-base-blue-color: #5bc0de;
$new-base-input-border-color: #ebebeb;
$banner-large: 25px;
body{
  background-color: #fff!important;
  .main{
    .container-fluid {
      padding: 0;
    }
  }
}
.theme-new-green{
  background-color: #fff;
  padding: 0!important;

  .search-icon-bg{
    background-image: url('../assets/img/icons/search-icon.png');
    background-repeat: no-repeat;
    background-size: 13px 13px;
    background-position: right 15px center;
  }

  .banner-area-1{
    background-color: $new-base-banner-bg;
    display: flex;
    width: 100%;
    max-width: 100%!important;
    padding: 10px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box!important;
    flex-direction: column;

    h1{
     color: $new-base-dark-color!important;
     font-family: $new-base-font!important;
     font-size: $banner-large!important;
     font-weight: 600!important;
     text-align: center;
     @media (max-width:767px) {
       font-size: 28px!important;
     }
    }

    &.set-mrg-10{
      width: calc(100% + 20px)!important;
      max-width: calc(100% + 20px)!important;
      margin-left: -10px!important;
      margin-right: -10px!important;
      margin-top: -9px!important;
    }
  }
  .bh-sl-form-container{
    padding: 30px 30px 0 30px;
    box-sizing: border-box!important;
    .form-group {
        input[type=text]{
          border: 1px solid $new-base-input-border-color;
          padding-right: 40px;
          font-family: $new-base-font;
          color: $new-base-dark-blue!important;
          font-weight: 500;
          font-size: 14px;
        }
        select {
          border: 1px solid $new-base-input-border-color;
          font-family: $new-base-font;
          color: $new-base-dark-blue!important;
          font-weight: 500;
          font-size: 14px;
        }
        .grid-type-buttons{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .first-part{
            display: inline-flex;
            input[type=radio]{
                background-color: transparent!important;
                border: 0!important;
                width: 0;
                height: 0;
                opacity: 0;
            }
            
            label {
              width: 32px;
              height: 32px;
              cursor: pointer;            
              position: relative;
              top: 7px;
            }
  
            .btn-grid-icon {
                background-image: url('../assets/img/icons/grid-gray-icon.png');
                background-repeat: no-repeat;
                background-size: 23px 24px;
                background-position: center;
                &:hover {
                    background-image: url('../assets/img/icons/grid-active-icon.png');
                }
                &.active {
                  background-image: url('../assets/img/icons/grid-active-icon.png');
                }
              }
            
            .btn-list-icon {
                background-image: url('../assets/img/icons/list-gray-icon.png');
                background-repeat: no-repeat;
                background-size: 23px 24px;
                background-position: center;
                &:hover {
                    background-image: url('../assets/img/icons/list-active-icon.png');
                }
                &.active {
                  background-image: url('../assets/img/icons/list-active-icon.png');
                }
            }
          }
          .second-part{
            display: inline-flex;
            flex-direction: row;
            padding-left: 10px;
            input[type=text]{
              width: 35px;
              height: 46px;
              font-family: $new-base-font;
              color: $new-base-dark-blue;
              padding: 0!important;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 500;
              font-size: 14px;
            }
            label{
              padding: 14px 10px;
              font-family: $new-base-font;
              color: $new-base-dark-blue;
              font-weight: 400;
            }
            span{
              padding: 14px 0;
              font-family: $new-base-font;
              color: $new-base-dark-blue;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
      .help-block {
        font-family: $new-base-font;
        font-size: 12px;
        color: $new-base-dark-blue;
        font-weight: 500;
        opacity: 0.7;
        line-height: 1em;
      }
      .has-display-msg{
        position: relative;
        width: calc(100% - 80px);
        max-width: 150px;
        em {
          position: absolute;
          left: 15px;
          top: 22%;
          width: auto;
          font-style: normal;
          font-family: "Maven Pro", sans-serif;
          color: #343e51 !important;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .pdl-50 {
        padding-left: 58px!important;
      }
      .mg-left-15{
        margin-left: 15px!important;
      }
      .search-btn{
        width: 50px!important;
        min-width: 50px;
        margin-top: 2px;
        background-color: $new-base-color!important;
        margin-left: 10px;
        &:hover{
          background-color: $new-base-blue-color!important;
        }
      }
    }
  }
  .bh-sl-map-container{
    padding: 0 30px;
    box-sizing: border-box!important;
    margin-top: 5px!important;

    .bh-sl-map{
        width: 100%!important;
    }

    .bh-sl-loc-list{
        width: 100%!important;
        height: auto!important;
    }
  }
  .event-result{
    width: 100%;
    display: grid;
    grid-template-columns: 49.5% 49.5%;
    grid-column-gap: 0.5%;
    margin: 0;
    overflow-x: hidden;

    @media (max-width:800px) {
      grid-template-columns: 99%;
    }

    &.grid-results{
      grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
      grid-column-gap: 0.75%;
      @media (max-width:800px) {
        grid-template-columns: 49.5% 49.5%;
      }
    }
    
    #bh-sl-map{      
      grid-column: 1 / 2;
      grid-row: 1/3;
      margin-bottom: 25px;
      &.grid-results{
        grid-column: 1 / 3;        
      }
    }
    .found-results{
      width: 100%;
      display: grid;
      grid-template-columns: 99%;
      
      app-grid-view{
        display: flex;
        width: 100%;
        max-width: 100%;
        margin-bottom: 25px;
        padding: 0 15px;
      }
      app-list-view{
        display: flex;
        width: 100%;
        max-width: 100%;
        margin-bottom: 25px;
      }
    }
  }
  .event-grid-view{
    width: 100%;
    .event-grid-view-img-preivew{
      img{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .event-grid-view-title-area{
      font-family: $new-base-font;
      font-size: 22px;
      color: $new-base-dark-blue;
      text-align: center;
      padding: 10px 5px;
      box-sizing: border-box;
      font-weight: 600;
      line-height: 1.25;
    }
    .event-grid-view-price-area{
      font-family: $new-base-font;
      font-size: 22px;
      color: $new-base-color;
      text-align: center;
      padding: 0 5px 15px 5px;
      box-sizing: border-box;
      font-weight: 800;
    }
    .event-grid-view-view-map {
      text-align: center;
      border-bottom: 1px solid $new-base-input-border-color;
      padding-bottom: 15px;
      a {
        font-family: $new-base-font;
        font-size: 15px;
        font-weight: 800;
        color: $new-base-color;
        text-transform: uppercase;
        svg {
            width: 15px;
            height: 15px;
            fill: $new-base-color;
        }
        &:hover{
            color: $new-base-blue-color;
            text-decoration: none;
            svg {
                fill: $new-base-blue-color!important;
            }
        }
      }
    }
    .event-grid-view-footer-info{
      display: flex;
      padding: 10px;
      .date-time-area{
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: $new-base-font;
        font-weight: 500;
        color: $new-base-dark-blue;
        span{
            width: 15px;
            height: 15px;
            background-image: url('../assets/img/icons/calander-new-green-icon.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 5px;
        }
      }
      .location-area{
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $new-base-input-border-color;
        font-family: $new-base-font;
        font-weight: 500;
        color: $new-base-dark-blue;
        span{
            width: 19px;
            height: 13px;
            background-image: url('../assets/img/icons/location-new-green-icon.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 5px;
        }
      }
    }
  }
  .event-list-view{
    width: 100%;
    display: flex;
    flex-direction: row;
    .event-list-view-img-preivew{
      width: 30%;
      max-width: 300px;
      img{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .event-list-view-contents-area{
      display: flex;
      flex-direction: column;
      width: 90%;
      padding-left: 15px;

      .event-list-view-title-and-price{
        display: flex;
        width: 100%;
        flex-direction: row;
        .event-list-view-title-area{
          width: 75%;
          font-family: $new-base-font;
          font-size: 22px;
          color: $new-base-dark-blue;
          text-align: left;
          padding: 10px 0;
          box-sizing: border-box;
          font-weight: 600;
          line-height: 1.25;
        }
        .event-list-view-price-area{
          width: 25%;
          font-family: $new-base-font;
          font-size: 22px;
          color: $new-base-color;
          text-align: right;
          padding: 10px 0;
          box-sizing: border-box;
          font-weight: 800;
        }

      }
      .event-list-view-view-map {
        text-align: left;
        padding-bottom: 15px;
        a {
          font-family: $new-base-font;
          font-size: 15px;
          font-weight: 600;
          color: $new-base-color;
          text-transform: uppercase;
          svg {
              width: 15px;
              height: 15px;
              fill: $new-base-color;
          }
          &:hover{
              color: $new-base-blue-color;
              text-decoration: none;
              svg {
                  fill: $new-base-blue-color!important;
              }
          }
        }
      }
      .event-list-view-footer-info{
        display: flex;
        padding: 0 0 10px 0;
        .date-time-area{
          display: inline-flex;
          width: auto;
          align-items: center;
          justify-content: center;
          padding-right: 25px;
          font-size: 14px;
          font-family: $new-base-font;
          font-weight: 500;
          color: $new-base-dark-blue;
          span{
              width: 15px;
              height: 15px;
              background-image: url('../assets/img/icons/calander-new-green-icon.png');
              background-repeat: no-repeat;
              background-size: contain;
              margin-right: 5px;
          }
        }
        .location-area{
          display: inline-flex;
          width: auto;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: $new-base-font;
          font-weight: 500;
          color: $new-base-dark-blue;
          span{
              width: 19px;
              height: 13px;
              background-image: url('../assets/img/icons/location-new-green-icon.png');
              background-repeat: no-repeat;
              background-size: contain;
              margin-right: 5px;
          }
        }
      }
      .event-list-view-description{
        padding: 20px 0;
        font-size: 14px;
        font-family: $new-base-font;
        font-weight: 500;
        color: $new-base-dark-blue;
      }
    }
  }
  .pagination-next-btn{
    width: 30px;
    height: 40px;
    background-color: transparent!important;
    background-image: url('../assets/img/icons/arrow-right.png')!important;
    background-repeat: no-repeat!important;
    background-position: center center!important;
    margin-left: 10px;
    &:hover{
      opacity: 0.8;
    }
  }
  .make-flex-row{
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .footer-pagination-area{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 15px;
    label{
      line-height: 1;
      margin: 0;
      padding: 15px 0;
      font-size: 14px;
      font-weight: 500;
      font-family: $new-base-font;
      margin-left: 14px;
    }
    span {
      line-height: 1;
      margin: 0;
      padding: 10px 14px;
      &.active{
        background-color: $new-base-color;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        font-family: $new-base-font;
      }
    }
  }

  .subheader-area-1{
    display: flex;
    padding: 10px 0;
    flex-direction: row!important;
    width: 100%;
    @media (max-width:767px) {
      flex-direction: column!important;      
    }
    .main-title-info{
      display: flex;
      font-weight: 500;
      font-family: $new-base-font;
      font-size: 15px;
      text-transform: none;
      align-items: center;
      justify-content: flex-start;
      width: 60%;
      @media (max-width:767px) {
        width: 100%;     
        justify-content: flex-start;
        align-items: flex-start;
      }
      strong{
        color:$new-base-dark-blue
      }
    }
    .main-title-info-sm{
      display: flex;
      font-weight: 500;
      font-family: $new-base-font;
      font-size: 15px;
      text-transform: none;
      align-items: center;
      justify-content: flex-end;
      width: 40%;
      @media (max-width:767px) {
        width: 100%;     
        justify-content: flex-start;
        align-items: flex-start;
      }
      strong{
        color:$new-base-dark-blue
      }
    }
  }

  
  .text-box{
    h2{
      font-family: $new-base-font!important;
      font-size: 15px;     
      color: #666; 
      font-weight: 500;
      text-transform: capitalize;
    }
    h3{
      font-size: 18px;
      color: $new-base-dark-color;
      font-family: $new-base-font!important;
      font-weight: 600;
      text-transform: none;
    }
    p{
      font-family: $new-base-font!important;
      font-size: 15px;     
      color: #666; 
      font-weight: 500;
      text-transform: capitalize;
    }
    label{
      font-family: $new-base-font!important;
      font-size: 15px;     
      color: #666; 
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  

  app-product{
    width: 100%;
    padding: 0 15px;
    margin-bottom: 35px;
    
    .product {
      margin: 0!important;
      width: 100%!important;
      .badge{
        padding: 5px;
      }
      .badge-secondary{
        color: #fff;
        background-color: $new-base-color!important;
        font-family: $new-base-font!important;
      }
      .image-box{
        position: relative;
      }
      
      .text-box{
        position: relative;
        padding-top: 38px;
        .edit-bar{
          background-color: rgba(106,185,99, 0.9);
          position: absolute;
          top: 3px;
          right: 62px;
          padding: 10px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          box-sizing: border-box;
          z-index: 5;
          transition: all 1s ease;
          -moz-transition: all 1s ease;
          -ms-transition: all 1s ease;
          -webkit-transition: all 1s ease;
          -o-transition: all 1s ease;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          button{
            background-color: transparent;
            padding: 0;
            margin: 0;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            svg{
              top: 8px;
              left: 3px;
            }
            &:hover{
              bottom: 0;
            }
          }
        }
        .delete-bar{
          background-color: rgba(255,99,99, 0.9);
          position: absolute;
          top: 3px;
          right: 10px;
          padding: 10px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          box-sizing: border-box;
          z-index: 5;
          transition: all 1s ease;
          -moz-transition: all 1s ease;
          -ms-transition: all 1s ease;
          -webkit-transition: all 1s ease;
          -o-transition: all 1s ease;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          button{
            background-color: transparent;
            padding: 0;
            margin: 0;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            svg{
              top: 8px;
              left: 3px;
              width: 19px;
              fill: #fff;
            }
            &:hover{
              bottom: 0;
            }
          }

        }
      }
    }
  }
  .product-filter-tabs{
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 5px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    .each-filter-tab{
      display: flex;
      label{
        padding: 10px 7.5px 0 7.5px;
        margin-left: 25px;
        margin-right: 25px;
        font-family: $new-base-font!important;
        border-bottom:2px solid #fff;
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        &.active{
          color: $new-base-color;
          border-bottom:2px solid $new-base-color;
        }
        @media (max-width:767px) {
          margin-left: 5px;
          margin-right: 5px;
          font-size: 15px;
          padding: 10px 5px 0 5px;                
        }
      }
    }
  }
  .product-details-more{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-bottom: 30px;
    a{
      cursor: pointer;
      font-family: $new-base-font!important;
      font-size: 18px;
      color: $new-base-color;
      text-transform: uppercase;
      text-decoration: none;
      &:hover{
        color:$new-base-blue-color;
        text-decoration: none;
      }
    }
  }

  .icon-calander-green{
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    background-image: url('../assets/img/icons/calander-new-green-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
    display: inline-block;
    margin-left: 5px;
  }

  .icon-location-green{
    width: 19px;
    height: 13px;
    min-width: 19px;
    min-height: 13px;
    background-image: url('../assets/img/icons/location-new-green-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
    display: inline-block;
    margin-left: 5px;
  }

  .icon-eye-green{
    width: 29px;
    height: 23px;
    min-width: 19px;
    min-height: 13px;
    background-image: url('../assets/img/icons/eye-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
    display: inline-block;
    margin-left: 5px;
  }


  .margin-btm-30{
    margin-bottom: 30px!important;
  }

  .setup-msg{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    .setup-msg-label{
      display: flex;
      width: 100%;
      padding: 0 0 10px 0;
      font-size: 16px;
      font-weight: 600;
      font-family: $new-base-font;
      color: $new-base-dark-color;
    }
    .setup-msg-list{
      display: flex;
      width: 100%;
      padding: 0 0 10px 0;
      @media (max-width:767px) {
        flex-direction: column;      
      }
      label{
        font-size: 16px;
        font-weight: 400;
        font-family: $new-base-font;
        color: $new-base-dark-color;
        padding: 7px 20px 7px 38px;
        cursor: pointer;
        margin: 0;
        border: 1px solid $new-base-input-border-color;
        border-radius: 80px;
        cursor: pointer;
        position: relative;
        .svg-icon{
          position: absolute;
          width: 16px;
          height: 16px;
          fill: $new-base-input-border-color;
          left: 10px;
          top: 11px;
        }
        &:not(:first-child){
          margin-left: 30px;
          @media (max-width:767px) {
            margin-left: 0;   
            margin-top: 12px;  
          }
        }
        &.active{
          background-color: $new-base-color;
          color: #fff;
          .svg-icon{
            position: absolute;
            width: 16px;
            height: 16px;
            fill: #fff;
          }
        }
      }
    }
  }

  .location-fields{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;

    &.less-top-mrg{
      margin-top: 5px;
    }
    
    .location-fields-label{
      display: flex;
      width: 100%;
      padding: 0 0 10px 0;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      font-family: $new-base-font;
      color: $new-base-dark-color;
    }
    select {
      border-color: $new-base-input-border-color!important;
      width: 100%;
      max-width: 90%;
      cursor: pointer;
    }

    .date-time-area-row{
      display: flex;
      width: 100%;
      flex-direction: row;
      @media (max-width:767px) {
        flex-direction: column;
      }
      .date-block{
        display: flex;
        font-size: 16px;
        font-weight: 400;
        font-family: $new-base-font;
        color: $new-base-dark-color;
        align-items: center;
        .svg-icon{
          width: 25px;
          height: 25px;
          min-width: 25px;
          min-height: 25px;
          fill: $new-base-color;
          margin-right: 5px;
        }
      }
      .time-block{
        display: flex;
        font-size: 16px;
        font-weight: 400;
        font-family: $new-base-font;
        color: $new-base-dark-color;
        align-items: center;
        margin-left: 50px;
        @media (max-width:767px) {
          margin-left: 0;
        }
        .svg-icon{
          width: 25px;
          height: 25px;
          min-width: 25px;
          min-height: 25px;
          fill: $new-base-color;
          margin-right: 5px;
        }
      }
    }
  }

  .product-setup-list{
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    gap: 1%;
    box-sizing: border-box;
    //margin-top: 40px;
    //margin-bottom: 150px;
    @media (max-width:1360px) {
      grid-template-columns: 32.33% 32.33% 32.33%;      
    }
    @media (max-width:767px) {
      grid-template-columns: 99%;      
    }
  }

  .default-hollow-btn{
    font-size: 14px;
    font-style: normal;
    text-transform: uppercase;
    border-radius: 80px;
    font-weight: 500;
    background: #fff;
    border: 1px solid #5cb85c;
    min-width: 120px;
    height: 40px;
    margin-left: 10px;
    color: #5cb85c;
    &:hover{
      color: #5bc0de;
      border: 1px solid #5bc0de;
    }
  }

  .date-time-columns{
    td {
      button{
        text-align: left!important;
        padding-left: 3px;
        margin-left: -10px;
        font-size: 15px;
      }
    }
  }
}

.schedule,
.event-location{
  &.theme-new-green{
    .schedule-form-area{
      margin-top: 20px;
      .label-style{
        font-size: 16px;
        font-weight: 500;
        font-family: $new-base-font;
        color: $new-base-dark-color;
        margin-bottom: 5px;
        @media (max-width:1230px){
          font-size: 14px;
        }
        @media (max-width:1130px){
          font-size: 13px;
        }
      }
      .control-style{
        border-color: $new-base-input-border-color;
      }
    }
    .results{
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-left: 25px;
      .header-title-area{
        display: flex;
        font-weight: 600;
        font-size: 18px;
        font-family: $new-base-font;
        color: $new-base-dark-color;
      }
      .no-results{
        display: flex;
        font-weight: 500;
        font-size: 16px;
        font-family: $new-base-font;
        color: $new-base-dark-blue;
      }
      .results-holder{
        display: grid;
        grid-template-columns: 32.33% 32.33% 32.33%;
        gap: 1%;
        box-sizing: border-box;
        margin: 15px 0 50px 0;    
        padding: 0;
        list-style: none;
        &.per-row-2-items{
          grid-template-columns: 49% 49%;
        }
        &.per-row-1-item{
          grid-template-columns: 99%;
        }
        li{
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box!important;
          margin: 0;
          padding: 10px 5px 10px 10px;
          list-style: none;
          border: 1px solid $new-base-input-border-color;
          background-color: #f9f9f9;
          border-radius: 10px;
          .extra-pad-lft{
            padding-left: 10px!important;
          }
          .date-field{
            display: flex;
            flex-direction: row;
            position: relative;
            padding: 5px 5px 10px 30px;
            svg{
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              fill: $new-base-color;
              position: absolute;
              left: 0;
              top: 6px;
            }
            label{
              display: flex;
              width: 100%;
              margin: 0;
              padding: 0;
              font-size: 14px;
              font-family: $new-base-font;
              color: $new-base-dark-blue;
              font-weight: 500;
              flex-direction: column;
              span{
                font-size: 14px;
                font-family: $new-base-font;
                color: $new-base-color;
                font-weight: 400;
              }
            }
          }
          .time-field{
            display: flex;
            flex-direction: row;
            position: relative;
            padding: 5px 5px 10px 30px;
            svg{
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              fill: $new-base-color;
              position: absolute;
              left: 0;
              top: 6px;
            }
            label{
              display: flex;
              width: 100%;
              margin: 0;
              padding: 0;
              font-size: 14px;
              font-family: $new-base-font;
              color: $new-base-dark-blue;
              font-weight: 500;
              flex-direction: column;
              span{
                font-size: 14px;
                font-family: $new-base-font;
                color: $new-base-color;
                font-weight: 400;
              }
            }
          }
          .bt-control{
            display: flex;
            flex-direction: row;
            position: relative;
            padding: 5px 5px 10px 30px;
            min-height: 36px;
            button {
              background-color: $new-base-color;
              position: absolute;
              bottom: 5px;
              right: 5px;
              padding: 10px;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              cursor: pointer;
              border: 0;
              opacity: 0.6;
              &:hover{
                opacity: 1;
              }
              svg{
                position: relative;                
                left: -3px;
                top: -6px;
              }
            }
          }
        }   
      }
    }
    .additional-title-info{
      display: flex;
      width: 100%;
      flex-direction: column;
      .first-title{
        display: flex;
        flex-direction: row;
        width: 100%;
        label{
          display: flex;
          flex-direction: row;
          width: 100%;
          padding: 0 0 5px 30px!important;
          position: relative;
          margin: 0;
          svg{
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            position: absolute;
            left: 0;
            fill: $new-base-color;
          }
        }
      }
      .second-titles{
        display: flex;
        flex-direction: row;
        width: 100%;
        label{
          width: auto;
          padding: 0 0 5px 30px!important;
          position: relative;
          svg{
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            position: absolute;
            left: 0;
            fill: $new-base-color;
          }
          &:last-child{
            margin-left: 50px!important;
          }
        }
      }
    }
    .extra-form-info{
      display: flex;
      width: 100%;
      .blocks-area{
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        label{
          font-size: 16px;
          font-weight: 500;
          font-family: $new-base-font;
          color: $new-base-dark-color;
          margin-bottom: 5px;
        }
        .block-field-values{
          display: flex;
          width: 100%;
          flex-direction: row;
          width: auto!important;
          flex: 0 0 auto!important;
          min-width: 230px;
          span{
            display: flex;
            padding-right: 25px;
            font-size: 16px;
            font-weight: 400;
            font-family: $new-base-font;
            color: $new-base-dark-color;
            padding-left: 30px;
            position: relative;
            svg{
              width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;
              position: absolute;
              left: 0;
              fill: $new-base-color;
              &.sp{
                stroke: $new-base-color;
                fill: #fff;
                width: 22px;
                height: 22px;
                min-width: 22px;
                min-height: 22px;
                stroke-width: 1.25px;
              }
            }

            i{
              width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;
              position: absolute;
              left: 0;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: contain;
              &.a{
                background-image: url('../assets/img/icons/icon-range-a.png');
              }
              &.b{
                background-image: url('../assets/img/icons/icon-range-b.png');
              }
            }
          }
        }
      }
    }
    .height-130{
      max-height: 130px!important;
    }
  }
}

.giveaway-badge{
  z-index: 98!important;
}

.btn-right-block{
  display: flex;
  justify-content: flex-end;
}

.app-footer{
  @media (min-width:991px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;       
  }
}

.ng-wizard-container{
  @media (min-width:991px) {
  padding-bottom: 55px !important;
  padding-top: 53px!important;
  border: 0!important;
  }
}

.ng-wizard-navigation-list{
  @media (min-width:991px) {
  position: fixed;
  top: 55px;
  z-index: 99;
  }
}

.row{
  &.adjust-for-add-popup{
    margin: 0.2em 0!important;

    input, .ng-select-container, textarea{
      border-color: #ebebeb;
      min-height: 46px;
      border-radius: 0!important;
    }

    textarea{
      height: 75px!important;
      line-height: 1.5;
    }

    .g-edit{
      .btn-primary{
        &.btn-xs{
          min-width: 40px!important;
        }
      }
    }
  }
}

.min-w-170{
  min-width: 170px;
}

.line-height-normal{
  line-height: 1.5em!important;
}

.ui-button.ui-button-rounded{
  border-radius: 50% !important;
}
.ui-button.ui-state-default.ui-button-success
{
  background-color: rgba(106,185,99,.9) !important;
  border-color: rgba(106,185,99,.9) !important;
}
.ui-button.ui-state-default.ui-button-danger
{
  background-color: rgba(255,99,99,.9) !important;
  border-color: rgba(255,99,99,.9) !important;
}
.ui-button.ui-state-default.ui-button-success:enabled:hover
{
  background-color: rgba(77, 151, 70, 0.9) !important;
  border-color: rgba(77, 151, 70, 0.9) !important;
}

.ui-button.ui-state-default.ui-button-danger:enabled:hover
{
  background-color: rgba(224, 52, 52, 0.9) !important;
  border-color: rgba(224, 52, 52, 0.9) !important;
}

.ui-toast {
  width: 25em !important;
}