a {
    cursor: pointer;
}

.help-block {
    font-size: 12px;
}

.has-error{
  text-align: left;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442 !important;
}
.has-error .form-control
 {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error ng-select
 {
  border-color: #a94442 !important;
  border: 1px solid; 
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

 .has-error ng-select.ng-select-focused {
  border-color: #a94442 !important;
  border: 1px solid;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}
.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.has-error .form-control-feedback {
  color: #a94442;
}

.loadinggif {
    background: url('http://www.hsi.com.hk/HSI-Net/pages/images/en/share/ajax-loader.gif') no-repeat right center;
    height:15px;
    width: 15px;
}

/*.inputwithoutBorder:focus {
    outline:none;
}*/
/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 1060;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

    /* Transparent Overlay */
    .loading:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
    }

    /* :not(:required) hides these rules from IE9 and below */
    .loading:not(:required) {
        /* hide "loading..." text */
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
    }

        .loading:not(:required):after {
            content: '';
            display: block;
            font-size: 10px;
            width: 1em;
            height: 1em;
            margin-top: -0.5em;
            -webkit-animation: spinner 1500ms infinite linear;
            -moz-animation: spinner 1500ms infinite linear;
            -ms-animation: spinner 1500ms infinite linear;
            -o-animation: spinner 1500ms infinite linear;
            animation: spinner 1500ms infinite linear;
            border-radius: 0.5em;
            -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
            box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
        }

        .ui-growl
        {
            z-index: 1021 !important;
        }


        .input-group .p-input-form-control{
            width: 70%;
            font-size: 0.875rem;
            line-height: 1.25;
            color: #3e515b;
            background-color: #fff;
            background-image: none;
            background-clip: padding-box;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }

        p-inputmask.p-input-form-control > .ui-inputtext{
            display:block;
            width: 100%;
            padding: 0.5rem 0.75rem;
            border-radius: 0;
            font-size: 0.875rem;
            line-height: 1.50;
            color: #3e515b;
            background-color: #fff;
            background-image: none;
            background-clip: padding-box;
            border: 1px solid #c2cfd6;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }

        .ui-inputtext.ng-dirty.ng-invalid, p-dropdown.ng-dirty.ng-invalid > .ui-dropdown, p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext, p-calendar.ng-dirty.ng-invalid > .ui-inputtext, p-chips.ng-dirty.ng-invalid > .ui-inputtext, p-inputmask.ng-dirty.ng-invalid > .ui-inputtext, p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box, p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box, p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch, p-listbox.ng-dirty.ng-invalid .ui-inputtext, p-multiselect.ng-dirty.ng-invalid > .ui-multiselect, p-spinner.ng-dirty.ng-invalid > .ui-inputtext, p-selectbutton.ng-dirty.ng-invalid .ui-button, p-togglebutton.ng-dirty.ng-invalid .ui-button { border-bottom-color: #c2cfd6 !important;}

span .active {
    font-weight: bold;
    color: #093;
}
span .inactive {
    font-weight: bold;
  color: brown;
}

span .pipe {
  padding: 0 5px;
  color: #999;
}

.ui-widget-content a {
  color: #16b !important;
  text-decoration: none !important;
}
.sidebar-minimized .sidebar .sidebar-minimizer::before {
  width: 100%;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}


.sidebar .sidebar-minimizer {
	position: relative;
	-ms-flex: 0 0 50px !important;
	flex: 188px 0 50px !important;
	background-color: rgba(0, 0, 0, .2);
	border: 0
}
.sidebar .sidebar-minimizer::before {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	content: "";
	background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nIzczODE4ZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 12.5px;
	transition: .3s
}

.sidebar .sidebar-minimizer:focus,
.sidebar .sidebar-minimizer.focus {
	outline: 0
}

.sidebar .sidebar-minimizer:hover {
	background-color: rgba(0, 0, 0, .3)
}

.sidebar .sidebar-minimizer:hover::before {
	background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+)
}


textarea {
  height: 100px;
}

.wHund input, .wHund select, .wHund textarea, .wHund button, .wHund label {
  width: 100%;
}

.upload label {
  float: left;
}

.upload input {
  background: #ffffff;
  max-width: 100%;
  padding: 0px;
  margin: 5px;
  border-radius: 3px;
  height: auto;
  float: left;
}

.upload textarea {
  height: 300px;
  width: 100%;
}

.upload span {
  margin-left: 5px;
  float: left;
  margin-top: 10px
}

div.content table.main
{
    margin: 0px;
}


.ng-select .ng-arrow-wrapper .ng-arrow {
	border-style: solid !important;
	border-width: 0.1rem 0.1rem 0 0 !important;
	height: 8px !important;
	width: 8px !important;
	top: -2px !important;
	transform: rotate(135deg) !important;
	color: rgba(0, 0, 0, 0.25) !important;
}

.ng-select .ng-clear-wrapper {
  color: #a6a6a6;
  background: rgba(0,0,0,0.25);
  /* width: 12px; */
  height: 14px;
  text-align: center;
  border-radius: 9px;
  vertical-align: middle;
  transition: color .3s ease;
  /* position: absolute; */
  right: 9px;
}

.ng-select .ng-clear-wrapper:hover {
	background: rgba(0, 0, 0, 0.45) !important
}

.ng-select .ng-clear-wrapper .ng-clear {
	color: #fff !important;
	font-size: 12px !important;
	position: absolute !important;
	left: 4px !important;
	line-height: 12px !important
}

.agm-map-container-inner
{
  border: 1px solid #c2cfd6 !important;
}

/*Product Listing & Summary*/
.product-list{
  overflow: auto;
}

.summary{
  display: flex;
  flex-direction: column;
}

.summary .row{
  flex-flow: row;
  margin: auto 0.5em !important;
}

.summary .product-list{
  flex-flow: column;
}

.listing-section {
  display: flex;
  flex-wrap: wrap;
}

.product {
  display: flex;
  flex-direction: column;
  border-radius: 2%;
  margin: 0.5em;
  width: 250px;
}

/* .product:hover {
  box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);  
  -moz-box-shadow:    1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
} */

.product .image-box {
  width: 100%;
  overflow: hidden;
  /* border-top: 0.01em solid #dddbdb;
  border-left: 0.01em solid #dddbdb;
  border-right: 0.01em solid #dddbdb; */
}

.product .image-box .giveaway-badge {
  position: absolute;
  z-index: 5000;
  margin: 3px;
}

.product .image-box .images {
  height: 10em;
  background-size: cover; 
  background-position: 50%; 
  background-repeat: no-repeat;
  border-radius: 2% 2% 0 0;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.product .image-box .images .image {
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  height: 15em;
}

.images:hover {
  transform: scale(1.2);
  overflow: hidden;
  border-radius: 2%;
}

.text-box {
  width: 100%;
  float: left;
  border: .01em solid #dddbdb;
  border-radius: 0 0 2% 2%;
  padding: 1em;
}

.text-box h2, .text-box h3 {
  float: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0.2em auto;
}

.text-box .item,.text-box .price {
  clear: left;
  width: 100%;
  text-align: center;
}

.text-box .price {
  color: Grey;
}

.text-box .description, .text-box label{
  float: left;
  clear: left;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1em;
  text-align: center;
  margin: 0.2em auto;
}

.event-approval .badge{
  font-size: 14px;
}

.ui-chkbox-label{
  white-space: pre-wrap !important;
  text-align: left !important;
}
